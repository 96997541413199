import React from "react"
import "../../assets/index.css"
import Layout from "../../layouts/layout"
import Hero from "../../components/hero-dei";

export default () => (
    <Layout  title="Diversity, Equity, and Inclusion | Ladd Partners">
        <Hero></Hero>
    </Layout>
)
