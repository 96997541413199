import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

const Flex = styled.div `
    max-width: 1110px;
    margin: 81px auto 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 81px;

    @media only screen and (max-width: 1120px) {
        padding: 0 20px;
    }

    @media only screen and (max-width: 767px) {
        margin-top: 33px;
        display: none;
    }
`

const FlexBody = styled.div `
    max-width: 1110px;
    margin: 81px auto 0px;
    margin-top: 81px;
    margin-bottom: 80px;

    @media only screen and (max-width: 1120px) {
        padding: 0 20px;
    }
`

const HeroTextDiv = styled.div `
    width: 100%;
`;

const HeroDiv = styled(BackgroundImage)`
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center bottom;
    background-color: #FFF;
    height: 240px;
    margin-top: 80px;

    @media only screen and (max-width: 767px) {
        height: 190px
    }
`;

const query = graphql`
    query {
        sanityHero(title: { eq: "DEI" }) {
            title
           heading
            subline
            video_url
            background_color
            image {
                asset {
                    url
                }
            }
            background_image {
                asset {
                    fluid(maxWidth: 1920) {
                        ...GatsbySanityImageFluid
                    }
                }
              }
          }
      }
  `

function createMarkup(data) { return {__html: data}; };

export default () => (
    <StaticQuery
        query={query}
        render={data => {
            return (
                <>
                <HeroDiv fluid={data.sanityHero.background_image.asset.fluid}>
                    <Flex>
                    <HeroTextDiv className="hero">
                        <div dangerouslySetInnerHTML={createMarkup(data.sanityHero.heading)}/>
                    </HeroTextDiv>
                    </Flex>
                </HeroDiv>
                
                <FlexBody>

                    <p>At Ladd Partners, we aim to drive growth and transformation for our employees and clients through diverse perspectives, innovative ideas, and inclusive practices. We are committed to fostering a workplace where each team member’s unique experiences and contributions are valued, and every individual can thrive.</p>
                    <h3>Our Core Principles of Diversity, Equity, and Inclusion (DEI)</h3>
                    
                    <p><strong>Diverse Hiring for Excellence</strong></p>
                    <p>We believe that diversity drives innovation and strengthens problem-solving. Our hiring practices are rooted in identifying and nurturing talent, focusing on a candidate’s skills, experiences, and potential rather than any personal attributes such as race, gender, sexual orientation, religion, or cultural background.</p>

                    <p><strong>Inclusive Workplace Culture</strong></p>
                    <p>We work continuously to cultivate an inclusive environment where every voice is heard, and individuals feel respected and empowered to be their authentic selves. Through open communication, active listening, and ongoing education, we aim to break down barriers, foster collaboration, and encourage curiosity and understanding among our team members.</p>

                    <p><strong>Equity in Opportunities and Advancement</strong></p>
                    <p>At Ladd Partners, equity means ensuring fair access to opportunities for growth and development for every employee. We are dedicated to removing technical barriers, eliminating biases, and ensuring all employees have access to mentorship, career progression, and skill-building resources that support their professional and personal success.</p>

                    <p><strong>Commitment to Accountability and Transparency</strong></p>
                    <p>We hold ourselves accountable to these principles by regularly assessing our DEI goals and engaging with employees and stakeholders in open conversations about our progress. Our leadership is committed to taking concrete actions that reflect our DEI values, sharing updates transparently, and continually refining our approach as we grow.</p>

                    <h3>Our Pledge</h3>
                    <p>We are committed to making DEI a core priority across our company. By embracing a wide range of perspectives, ideas, and experiences, we empower our team to deliver innovative solutions and exceptional outcomes for our clients. At Ladd Partners, we recognize each individual’s unique contributions, value every voice, and strive to build a more inclusive, equitable, and impactful future together.</p>
                                    
                </FlexBody>
                </>
            )
        }}
    />
)